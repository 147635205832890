<template>
  <div :style="contentStyle">
    <mu-appbar
      style="width: 100%"
      :title="siteSettings.siteName"
      color="primary"
    />
    <mu-container style="margin-top: 20px">
      <!-- header begin -->
      <div v-html="header" />
      <!-- header end -->
      <mu-paper v-if="showStatus === 'input'" :z-depth="1">
        <mu-row>
          <!--Basic information form-->
          <mu-form
            :model="basicInfo"
            class="gradeForm"
            label-position="top"
            label-width="100"
          >
            <!--province select-->
            <mu-form-item prop="select" label="省份">
              <mu-select
                v-model="basicInfo.provinceSelect"
                @change="onProvinceAndGenderChoose()"
              >
                <mu-option
                  v-for="item in provinceList"
                  :key="item.alias"
                  :label="item.name"
                  :value="item.alias"
                />
              </mu-select>
            </mu-form-item>
            <!--gender select-->
            <mu-form-item prop="radio" label="性别">
              <mu-radio
                v-model="basicInfo.genderSelect"
                value="male"
                label="男子"
                @click="onProvinceAndGenderChoose()"
              />
              <mu-radio
                v-model="basicInfo.genderSelect"
                value="female"
                label="女子"
                @click="onProvinceAndGenderChoose()"
              />
            </mu-form-item>
          </mu-form>
          <mu-divider />
          <!--Score information form-->
          <mu-form
            :model="scoreForm"
            class="gradeForm"
            label-position="top"
            label-width="100"
          >
            <!--score input-->
            <div v-for="(item, index) in itemList" :key="index">
              <!--time choose-->
              <mu-form-item
                v-if="item.type === 'time'"
                prop="date"
                :label="item.name"
                :error-text="item.error_info"
              >
                <mu-row gutter>
                  <mu-col span="4">
                    <mu-text-field
                      v-model="item.value[0]"
                      type="number"
                      suffix="分"
                      @focus="onInputFocus(index)"
                    />
                  </mu-col>
                  <mu-col span="4">
                    <mu-text-field
                      v-model="item.value[1]"
                      type="number"
                      suffix="秒"
                      @focus="onInputFocus(index)"
                    />
                  </mu-col>
                  <mu-col span="4">
                    <mu-text-field
                      v-model="item.value[2]"
                      type="number"
                      @focus="onInputFocus(index)"
                    />
                  </mu-col>
                </mu-row>
              </mu-form-item>
              <!--inputs-->
              <mu-form-item
                v-else-if="item.type === 'len' || item.type === 'num'"
                prop="input"
                :label="item.name"
                :error-text="item.error_info"
              >
                <mu-text-field
                  v-model="item.value"
                  type="number"
                  :suffix="item.type === 'len' ? '米' : '个'"
                  @focus="onInputFocus(index)"
                />
              </mu-form-item>
            </div>
            <mu-form-item
              v-if="haveCultureScore && itemList.length > 0"
              prop="input"
              label="文化分"
            >
              <mu-text-field v-model="culturalScore" type="number" />
            </mu-form-item>
          </mu-form>
        </mu-row>
        <mu-flex justify-content="center" align-items="center">
          <mu-button
            style="margin-bottom: 20px"
            round
            color="success"
            @click="onInquireScore()"
            >查询成绩
          </mu-button>
        </mu-flex>
      </mu-paper>
      <mu-paper v-if="showStatus === 'result'" :z-depth="1">
        <mu-data-table stripe :columns="resultColumns" :data="resultListCom()">
          <template slot-scope="scope">
            <td>{{ scope.row.item }}</td>
            <td class="is-right">{{ scope.row.score }}</td>
          </template>
        </mu-data-table>
        <mu-flex justify-content="center" align-items="center">
          <mu-button
            style="margin-bottom: 20px; margin-top: 20px"
            round
            color="primary"
            @click="showStatus = 'input'"
            >继续查询
          </mu-button>
        </mu-flex>
      </mu-paper>

      <!-- footer begin -->
      <div v-html="footer" />
      <!-- footer end -->
    </mu-container>
  </div>
</template>

<script>
import qrCodeSrc from "../assets/qrcode.jpg";

export default {
  data() {
    return {
      provinceList: [],
      priorProvince: ["福建", "安徽", "河北"],
      basicInfo: {
        provinceSelect: "",
        genderSelect: "",
      },
      itemList: [],
      scoreForm: {},
      showStatus: "welcome",
      resultColumns: [
        {
          title: "项目",
          name: "item",
          cellAlign: "center",
        },
        {
          title: "成绩",
          name: "score",
          align: "center",
          cellAlign: "left",
          sortable: true,
        },
      ],
      resultList: [],
      qrCodeSrc: qrCodeSrc,
      culturalScore: "",
      siteSettings: {
        siteName: "default site name",
        indexBackgroundImage: "",
        indexHeader: "",
        indexFooter: "",
        resultBackgroundImage: "",
        resultHeader: "",
        resultFooter: "",
        welcomeBackgroundImage: "",
        welcomeContent: "",
        welcomeTime: 0,
      },
    };
  },
  computed: {
    footer() {
      if (this.showStatus === "input") {
        return this.siteSettings.indexFooter;
      } else if (this.showStatus === "result") {
        return this.siteSettings.resultFooter;
      } else if (this.showStatus === "welcome") {
        return "";
      }
      return "";
    },
    header() {
      if (this.showStatus === "input") {
        return this.siteSettings.indexHeader;
      } else if (this.showStatus === "result") {
        return this.siteSettings.resultHeader;
      } else if (this.showStatus === "welcome") {
        return this.siteSettings.welcomeContent;
      }
      return "";
    },
    backgroundImage() {
      if (this.showStatus === "input") {
        return this.siteSettings.indexBackgroundImage;
      } else if (this.showStatus === "result") {
        return this.siteSettings.resultBackgroundImage;
      } else if (this.showStatus === "welcome") {
        return this.siteSettings.welcomeBackgroundImage;
      }
      return "";
    },
    contentStyle() {
      return {
        background: `url(${this.backgroundImage})`,
        "min-height": `100vh`,
      };
    },
    cultureFactor() {
      const idx = this.provinceList.findIndex(
        (item) => item.alias == this.basicInfo.provinceSelect
      );
      return idx === -1 ? "" : this.provinceList[idx].cultureFactor;
    },
    physicalFactor() {
      const idx = this.provinceList.findIndex(
        (item) => item.alias == this.basicInfo.provinceSelect
      );
      return idx === -1 ? "" : this.provinceList[idx].physicalFactor;
    },
    haveCultureScore() {
      return this.cultureFactor > 0 || this.physicalFactor > 0;
    },
  },
  created() {
    this.axios.get("/settings").then((response) => {
      this.siteSettings = response.data;
      document.title = this.siteSettings.siteName;
      setTimeout(() => {
        this.showStatus = "input";
      }, this.siteSettings.welcomeTime);
    });

    this.axios.get("/sportscalc/province/").then(
      (response) => {
        this.provinceList = [];
        for (let i = 0; i < response.data.length; i++) {
          if (this.priorProvince.includes(response.data[i].name)) {
            this.provinceList.splice(0, 0, { ...response.data[i] });
          } else {
            this.provinceList.push({ ...response.data[i] });
          }
        }
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  },
  methods: {
    resultListCom() {
      return this.resultList;
    },

    milliSecondHelper(num) {
      let snum = num.toString();
      while (snum.length < 3) {
        snum += "0";
      }
      return parseInt(snum);
    },

    myParseFloat(num) {
      if (!num) {
        return 0;
      }
      if (num.length === 0) {
        return 0;
      }
      return parseFloat(num);
    },

    myParseInt(num) {
      if (num.length === 0) {
        return 0;
      }
      return parseInt(num);
    },

    onInquireScore() {
      let validateStatus = true;
      for (let i = 0; i < this.itemList.length; i++) {
        validateStatus = this.validateValue(i) ? validateStatus : false;
      }
      if (!validateStatus) {
        return;
      }
      const inquireData = {
        ScoreData: [],
      };
      for (let i = 0; i < this.itemList.length; i++) {
        let value = "0";
        if (this.itemList[i].type === "time") {
          value =
            this.myParseInt(this.itemList[i].value[0]) * 60 * 1000 +
            this.myParseInt(this.itemList[i].value[1]) * 1000 +
            this.milliSecondHelper(this.itemList[i].value[2]);
          value = value.toString();
        } else if (this.itemList[i].type === "len") {
          value = this.myParseFloat(this.itemList[i].value) * 100;
          value = value.toString();
        } else if (this.itemList[i].type === "num") {
          value = this.myParseFloat(this.itemList[i].value);
        } else {
          throw new Error("ITEM_TYPE_ERROR");
        }
        if (value === "0") {
          continue;
        }
        inquireData.ScoreData.push({
          TestItem: this.itemList[i].name,
          Value: Number(value),
        });
      }
      this.axios
        .post(
          "/sportscalc/score/" +
            this.basicInfo.provinceSelect +
            "/" +
            this.basicInfo.genderSelect +
            "/",
          inquireData
        )
        .then(
          (response) => {
            this.resultList = [];
            this.showStatus = "result";
            let sum = 0;
            for (let i = 0; i < response.data.length; i++) {
              this.resultList.push({
                item: response.data[i].testItem,
                score: response.data[i].value,
              });
              sum += this.myParseFloat(response.data[i].value);
            }
            if (this.haveCultureScore) {
              this.resultList.push({
                item: "体育分",
                score: sum,
              });
              this.resultList.push({
                item: "文化分",
                score: this.culturalScore,
              });
              sum =
                Math.round(sum) * this.physicalFactor +
                this.culturalScore * this.cultureFactor;
            }
            this.resultList.push({
              item: "合计",
              score: sum.toString(),
            });
          },
          (err) => {
            Promise.reject(err);
          }
        );
    },

    onProvinceAndGenderChoose() {
      if (
        this.basicInfo.provinceSelect.length <= 0 ||
        this.basicInfo.genderSelect.length <= 0
      ) {
        return;
      }
      this.axios
        .get(
          "/sportscalc/province/" +
            this.basicInfo.provinceSelect +
            "/" +
            this.basicInfo.genderSelect +
            "/"
        )
        .then(
          (response) => {
            this.itemList = [];
            for (let i = 0; i < response.data.length; i++) {
              this.itemList.push({
                name: response.data[i].name,
                type: response.data[i].type,
                value: response.data[i].type === "time" ? ["", "", ""] : "",
                error_info: "",
              });
            }
          },
          (err) => {
            return Promise.reject(err);
          }
        );
    },

    validateValue(itemIdx) {
      const validateTime = [
        {
          validate: (val) => val >= 0,
          message: "应输入大于零的数",
        },
        // {validate: (val) => val < 60, message: '时间不合法'}
      ];
      const validateNum = [
        {
          validate: (val) => val >= 0,
          message: "应输入大于零的数",
        },
      ];
      const item = this.itemList[itemIdx];
      if (item.type === "time") {
        for (let i = 0; i < 3; i++) {
          for (let vi = 0; vi < validateTime.length; vi++) {
            if (!validateTime[vi].validate(this.myParseFloat(item.value[i]))) {
              item.error_info = validateTime[vi].message;
              return false;
            }
          }
        }
      } else {
        for (let vi = 0; vi < validateNum.length; vi++) {
          if (!validateNum[vi].validate(this.myParseFloat(item.value))) {
            item.error_info = validateNum[vi].message;
            return false;
          }
        }
      }
      item.error_info = "";
      return true;
    },

    onInputFocus(itemIdx) {
      this.validateValue(itemIdx);
    },
  },
};
</script>

<style scoped>
.gradeForm {
  padding: 20px;
}
</style>
